import styled from "styled-components"
import { device, colors } from '../styles/stylesConfig';
import Title from "./Title"
import { memo, useState } from "react"
import { PvcColorProps } from "../interfaces/WorkInterfaces"
import { ColorProps, ProductColorsProps } from "../interfaces/TextsInterfaces"

const InfoContainer = styled.div`
    width: 100%;
    max-width: 1200px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
   
    @media ${device.lg} {
        width: 85%;
    }

    @media ${device.md} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.sm} {
        grid-template-columns: repeat(1, 1fr);
    }
 
`

const Card = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0;
        transition: all 0.3s ease;
    }

    &:hover {
        transform: scale(1.03);
    }

    h1 {
        font-size: 16px; 
        margin-top: 20px; 
        width: 100%;
        text-align: left;
        font-weight: 500 !important;
        text-transform: capitalize;

        @media ${device.md} {
            font-size: 14px;
            margin-top: 15px; 
        }

        @media ${device.xs} {
            font-size: 12px;

        }
    }

    &.dark {
        transition: all 0.3s ease;

        &::before {
            opacity: 0.2;
        }
    }
`

const Image = styled.img`
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.2s ease;

    &:hover {
        transform: scale(1.03);
    }

`

interface Props {
    data: ColorProps[]
}

const PvcColorsComponent = ({ data }: Props) => {


    return (

        <InfoContainer>

            {
                data.map((item: ColorProps) => {
                    return (

                        <Card key={item.id}>

                            <Image className="utils__fade-in-fast-top" src={item.image} alt={item.title} loading="lazy" />

                            <Title title={item.title} style={{ margin: 0 }} />

                        </Card>

                    )
                })
            }

        </InfoContainer>

    )
}

const PvcColors = memo(PvcColorsComponent);

export default PvcColors;