import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Form from "../components/Form";
import { allAnimations } from "../effects/Observers"

export const ContactScreen = () => {

    const location = useLocation()

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])


    return (

        <Form screen="Contact" />

    )
}
