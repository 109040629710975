import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import AboutImage from "../components/AboutImage"
import WhoAreWe from "../components/WhoAreWe"
import { allAnimations } from "../effects/Observers"
import ScreenContainer from "../components/ScreenContainer"

export const AboutScreen = () => {

    const location = useLocation()

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])

    return (

        <ScreenContainer id="about">

            <AboutImage />

            <WhoAreWe />

        </ScreenContainer>

    )
}
