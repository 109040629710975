import { styled } from 'styled-components';
import { device } from '../styles/stylesConfig';
import { memo } from 'react';


const Container = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.75s ease-in;
    cursor: pointer;
    position: relative;

    & > img:first-of-type {
        position: absolute;
        left: 0px;
        transform: scale(0) translateX(300%);
    }

    &:hover {

        & > img:first-of-type {
            transform: scale(1) translateX(2px);
        }

        & > div {
            transform: translateX(50px);

        }

        & > img:last-of-type {
            transform: scale(0) translateX(-100%);

        }
    }

    @media ${device.lg} {
        visibility: hidden;
        position: absolute;
    }

`

const ArrowImage = styled.img`
    height: 25px;
    width: 25px;
    border-radius: 50px;
    background-color: #004bb5;
    padding: 15px;
    transition: transform 0.3s ease;
    margin: 0;
`

const QuotatorNavButton = styled.div`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 0px 20px;
    position: relative;
    transition: all 0.3s ease;
    background-color: #004bb5;
    border-radius: 50px;
    z-index: 1;
    margin: 0;

    &:focus,
    &:hover {
        outline: none;
    }

`

interface Props {
    onClick: () => void;
}

const QuotatorButtonComponent = ({ onClick }: Props) => {

    return (

        <Container onClick={async () => {
            onClick()
        }}>

            <ArrowImage
                src={require('../images/flecha.png')}
                alt="Flecha"
            />

            <QuotatorNavButton>
                Cotiza tu obra
            </QuotatorNavButton>

            <ArrowImage
                src={require('../images/flecha.png')}
                alt="Flecha"
            />

        </Container>

    )
}

const QuotatorButton = memo(QuotatorButtonComponent);

export default QuotatorButton;