import { memo, useEffect, useRef, useState } from "react";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { initialStateWork, worksList } from "../utils/Jsons";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";


const Container = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.8);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 6;
    backdrop-filter: blur(5px);
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
`

const ContentContainer = styled.div`
    height: 100%;
    position: relative;
    gap: 4rem;
    row-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }

    width: 70vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    scroll-snap-type: x mandatory;
    padding: 2rem 30rem;


    @media ${device.md} {
        width: 80vw;
    } 

    
    @media ${device.sm} {
        width: 80vw;
    } 

    @media ${device.xs} {
        width: 70vw;
    } 

`

const InfoContainer = styled.img`
    width: 100%;
    max-height: 70vh;
    flex-shrink: 0;
    background: #fff;
    overflow: hidden; 
    position: sticky;
    object-fit: cover;
    scroll-snap-align: center;
    transition: all 0.5s;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.30);
`

const Close = styled.img`
    width: 20px;
    height: 20px;
    position: fixed;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    overflow: hidden;
    z-index: 100;
`

const ArrowContainer = styled.div`
    position: fixed;
    right: 3rem;
    top: 0px;
    bottom: 0px;
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    //

    position: absolute;
    right: 0px;
    left: 0px;
    top: 10vh;
    bottom: 10vh;
    height: fit-content;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    display: none; // SE HACE EL DISPLAY NONE YA QUE SOLO SE MUESTRA UNA SOLA FOTO
`

const ArrowLeft = styled.div`
    position: relative;
    cursor: pointer;

    span {
        display: block;
        width: 1rem;
        height: 1rem;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
        animation: animateGoLeft 3s ease-in-out infinite;

        @media ${device.md} {
            width: 0.5rem;
            height: 0.5rem;
        }
    }

`

const ArrowRight = styled.div`
    position: relative;
    cursor: pointer;

    span {
        display: block;
        width: 1rem;
        height: 1rem;
        border-bottom: 3px solid white;
        border-right: 3px solid white;
        animation: animateGoRight 3s ease-in-out infinite;

        @media ${device.md} {
            width: 0.5rem;
            height: 0.5rem;
        }
    }

`

const Counter = styled.div`
    position: absolute;
    bottom: 10vh;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & > div {
        width: 10px;
        height: 10px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-left: 7.5px;
        margin-right: 7.5px;
        border-radius: 10rem;
        z-index: 1;
    }

    @media ${device.sm} {
        bottom: 5vh;
    } 


`

interface Props {
    showModal: {
        id: number;
        image: number;
    } | null
    setShowModal: React.Dispatch<React.SetStateAction<{
        id: number;
        image: number;
    } | null>>
}

export const WorkModalComponent = ({ showModal, setShowModal }: Props) => {

    const containerRef = useRef(null);

    const history = useHistory();

    const location = useLocation();

    const [visibleImage, setVisibleImage] = useState(showModal?.image ?? 0);

    const [workDetail] = useState<WorkProps>(worksList.find((value) => { return value.id === showModal?.id }) ?? initialStateWork);

    const cambiarImagen = (direction: any) => {

        const totalImagenes = workDetail.images.length;

        let nuevaImagenVisible = visibleImage + direction;

        if (nuevaImagenVisible === -1) {
            nuevaImagenVisible = totalImagenes - 1;
        } else if (nuevaImagenVisible < 1) {
            nuevaImagenVisible = 0;
        } else if (nuevaImagenVisible >= totalImagenes) {
            nuevaImagenVisible = 0;
        }

        setVisibleImage(nuevaImagenVisible);
    };

    const handleEscKeyPress = (event: any) => {
        if (event.key === 'Escape') {
            setShowModal(null)
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscKeyPress);
        return () => {
            window.removeEventListener('keydown', handleEscKeyPress);
        };
    }, []);

    return (

        <Container id="modal">

            <button
                onClick={() => {
                    setShowModal(null)
                }}
            >

                <Close
                    className='workModal__close-image'
                    src={require('../images/cerrar.webp')}
                    alt='logo'
                    id="cerrarModal"
                />

            </button>

            <ArrowContainer>

                <ArrowLeft onClick={() => cambiarImagen(-1)}>
                    <span></span>
                </ArrowLeft>

                <ArrowRight onClick={() => cambiarImagen(1)}>
                    <span></span>
                </ArrowRight>

            </ArrowContainer>


            <Counter>

                {
                    workDetail.images.map((value, index) => (

                        <div
                            style={{ backgroundColor: index === visibleImage ? 'white' : undefined }}
                            key={value}
                        />

                    ))
                }

            </Counter>


            <ContentContainer ref={containerRef}>

                <InfoContainer
                    src={workDetail.images[visibleImage]}
                    alt='logo'
                    id="cerrarModal"
                />

            </ContentContainer>

        </Container>

    )
}

const WorkModal = memo(WorkModalComponent);

export default WorkModal;