import { Suspense, lazy, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers"
import ScreenContainer from "../components/ScreenContainer"
import LoadingComponent from "../components/LoadingComponent"

const Products = lazy(() => import("../components/Products"));

export const ProductsScreen = () => {

    const location = useLocation()

    useEffect(() => {

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname])


    return (

        <ScreenContainer>

            <hr style={{ border: 'none', position: "absolute", top: -1000 }} id="products" />

            <Suspense fallback={<LoadingComponent />}>

                <Products screen="Products" />

            </Suspense>

        </ScreenContainer>

    )
}
