import { TextProductProps } from "../interfaces/TextsInterfaces";

export const hydroTexts: TextProductProps = {
    mainSection: {
        title: 'SISTEMA HYDRO',
        image: require('../images/casaabertura.jpeg'),
        description: `Hydro es una empresa global de aluminio, líder en la producción y suministro de aluminio 
        y productos de aluminio. Con operaciones en más de 40 países, Hydro se dedica a desarrollar soluciones 
        innovadoras y sostenibles en aluminio, que abarcan desde la extracción de bauxita y refinación de 
        alúmina hasta la producción de aluminio primario y productos de aluminio reciclado. La compañía se 
        enfoca en la sostenibilidad y la eficiencia energética, proporcionando materiales de alta calidad para 
        una variedad de industrias, incluyendo la automotriz, la construcción y la electrónica.`,
        systems: [
            {
                title: 'HYDRO',
                types: ['SAPA HA 110', 'SAPA HA 62'],
                image: require('../images/hydro.png')
            },
        ]
    },
    firstData: [
        {
            title: 'AISLAMIENTO SONORO',
            description: `El aislamiento sonoro en las aberturas de aluminio proporciona una solución efectiva para 
                reducir la transmisión de ruido no deseado desde el exterior hacia el interior de una casa, así 
                como para minimizar la propagación del ruido generado dentro del edificio hacia el exterior.`,
            image: require('../images/aislamiento-sonoro.jpg')
        },
        {
            title: 'AISLAMIENTO TERMICO',
            description: `El aislamiento térmico en las aberturas de aluminio no solo mejora la eficiencia energética 
                y el confort interior, sino que también ofrece durabilidad y bajo mantenimiento, convirtiéndolo en una 
                opción excelente para cualquier proyecto de construcción o renovación.`,
            image: require('../images/aislamiento-sonoro.jpg')
        },
    ],
    profiles: [
        {
            title: 'PERFILES DE HYDRO',
            profiles: [
                {
                    id: 0,
                    title: 'Sapa HA110',
                    description: `Hydro HA110 destaca por su mayor resistencia mecánica, siendo óptimo 
                    para usos que demandan alta robustez y capacidad de carga. Es preferido en estructuras 
                    que soportan grandes esfuerzos, ofreciendo una excelente rigidez y estabilidad.`,
                    image: require('../images/ha110.webp'),
                },
                {
                    id: 1,
                    title: 'Sapa HA62 Batiente',
                    description: `Hydro HA62 Batiente es un sistema de ventanas de alta calidad, conocido por su 
                    durabilidad y excelente aislamiento térmico y acústico. Ideal para proyectos residenciales 
                    y comerciales, ofrece un cierre hermético y está disponible en diversos acabados y colores.`,
                    image: require('../images/ha62-bat.webp'),
                },
                {
                    id: 2,
                    title: 'Sapa HA62 Corrediza',
                    description: `Hydro HA62 Corrediza es un sistema de ventanas y puertas corredizas, reconocido por su durabilidad, 
                    deslizamiento suave, y excelente aislamiento térmico y acústico. Ideal para proyectos residenciales 
                    y comerciales, ofrece múltiples acabados y colores para adaptarse a cualquier estilo.`,
                    image: require('../images/ha62-corre.webp'),
                },
            ]
        },

    ],
    lastData: [
        {
            title: 'MULTIPLES TIPOLOGIAS',
            description: `Nos adaptamos a las particularidades de cada proyecto, diseñando aberturas que se ajusten 
                perfectamente a sus requerimientos específicos. Brindamos orientación experta a nuestros clientes para 
                garantizar que alcancen los resultados más favorables.`,
            image: require('../images/dibujo-aberturas.png')
        },
    ],
    colors: {
        title: 'VARIEDAD DE COLORES',
        description: `Los perfiles de aluminio ofrecen una gama diversa de colores y texturas para satisfacer 
                cualquier preferencia estética. Desde acabados anodizados mate, lijados y pulidos hasta pinturas en 
                mate, semimate y microtexturadas, así como opciones similares a la madera.`,
        colors: [
            {
                id: 0,
                title: 'Anodizado Bronce',
                image: require('../images/anodizado-bronce.webp'),

            },
            {
                id: 1,
                title: 'Anodizado Champagne',
                image: require('../images/anodizado-champagne.webp'),

            },
            {
                id: 2,
                title: 'Anodizado Natural',
                image: require('../images/anodizado-natural.webp'),

            },
            {
                id: 3,
                title: 'Anodizado Negro',
                image: require('../images/anodizado-negro.webp'),

            },
            {
                id: 4,
                title: 'Gris Microtexturado',
                image: require('../images/gris-microtexturado.webp'),

            },
            {
                id: 5,
                title: 'Negro Microtexturado',
                image: require('../images/negro-microtexturado.webp'),

            },
            {
                id: 6,
                title: 'Marron Claro',
                image: require('../images/marron-claro.webp'),

            },
            {
                id: 7,
                title: 'Marron Oscuro',
                image: require('../images/marron-oscuro.webp'),

            },
        ]
    },
}

export const aluarTexts: TextProductProps = {
    mainSection: {
        title: 'SISTEMA ALUAR',
        image: require('../images/casav3.webp'),
        description: `Las aberturas de aluminio son estructuras diseñadas para permitir el paso de personas,
                aire, luz u objetos a través de una apertura en una pared, puerta o ventana. Están fabricadas
                principalmente con aluminio, un material resistente, duradero y ligero que ofrece varias ventajas,
                como resistencia a la corrosión, bajo mantenimiento y una amplia variedad de diseños y acabados.
                Las aberturas de aluminio son comunes en la construcción de edificios residenciales, comerciales
                e industriales debido a su versatilidad, capacidad para soportar grandes cargas y su capacidad para
                adaptarse a diferentes estilos arquitectónicos. Además, son conocidas por su eficiencia energética,
                ya que pueden integrarse con vidrios de bajo coeficiente de transferencia térmica para mejorar el
                aislamiento térmico y acústico de los espacios interiores.`,
        systems: [
            {
                title: 'ALUAR',
                types: ['A30', 'MODENA'],
                image: require('../images/aluar.png')
            }
        ]
    },
    firstData: [
        {
            title: 'AISLAMIENTO SONORO',
            description: `El aislamiento sonoro en las aberturas de aluminio proporciona una solución efectiva para 
                reducir la transmisión de ruido no deseado desde el exterior hacia el interior de una casa, así 
                como para minimizar la propagación del ruido generado dentro del edificio hacia el exterior.`,
            image: require('../images/aislamiento-sonoro.jpg')
        },
        {
            title: 'AISLAMIENTO TERMICO',
            description: `El aislamiento térmico en las aberturas de aluminio no solo mejora la eficiencia energética 
                y el confort interior, sino que también ofrece durabilidad y bajo mantenimiento, convirtiéndolo en una 
                opción excelente para cualquier proyecto de construcción o renovación.`,
            image: require('../images/aislamiento-sonoro.jpg')
        },
    ],
    profiles: [
        {
            title: 'PERFILES MODENA',
            profiles: [
                {
                    id: 0,
                    title: 'Linea Abatible Modena',
                    description: `Solución arquitectónica moderna y funcional que permite una apertura completa, 
                    ideal para maximizar la ventilación y el acceso.`,
                    image: require('../images/linea-abatible-modena.webp'),
                },
                {
                    id: 1,
                    title: 'Linea Corrediza Modena',
                    description: `Diseño elegante y práctico que optimiza el espacio disponible, proporcionando 
                    una apertura suave y eficiente.`,
                    image: require('../images/linea-corrediza-modena.webp'),
                },
            ]
        },
        {
            title: 'PERFILES A30',
            profiles: [
                {
                    id: 0,
                    title: 'Linea Abatible A30',
                    description: `Elegantes y resistentes, estas aberturas permiten una ventilación superior 
                    y un acceso completo, perfectas para cualquier entorno que requiere funcionalidad y estilo.`,
                    image: require('../images/linea-abatible-a30.webp'),
                },
                {
                    id: 1,
                    title: 'Linea Corrediza A30',
                    description: `Estas aberturas combinan diseños modernos con eficiencia espacial, ofreciendo 
                    una operación suave y continua, ideal para maximizar el uso del espacio disponible.`,
                    image: require('../images/linea-corrediza-a30.webp'),
                },

            ]
        }
    ],
    lastData: [
        {
            title: 'MULTIPLES TIPOLOGIAS',
            description: `Nos adaptamos a las particularidades de cada proyecto, diseñando aberturas que se ajusten 
                perfectamente a sus requerimientos específicos. Brindamos orientación experta a nuestros clientes para 
                garantizar que alcancen los resultados más favorables.`,
            image: require('../images/dibujo-aberturas.png')
        },
    ],
    colors: {
        title: 'VARIEDAD DE COLORES',
        description: `Los perfiles de aluminio ofrecen una gama diversa de colores y texturas para satisfacer 
                cualquier preferencia estética. Desde acabados anodizados mate, lijados y pulidos hasta pinturas en 
                mate, semimate y microtexturadas, así como opciones similares a la madera.`,
        colors: [
            {
                id: 0,
                title: 'Anodizado Bronce',
                image: require('../images/anodizado-bronce.webp'),

            },
            {
                id: 1,
                title: 'Anodizado Champagne',
                image: require('../images/anodizado-champagne.webp'),

            },
            {
                id: 2,
                title: 'Anodizado Natural',
                image: require('../images/anodizado-natural.webp'),

            },
            {
                id: 3,
                title: 'Anodizado Negro',
                image: require('../images/anodizado-negro.webp'),

            },
            {
                id: 4,
                title: 'Gris Microtexturado',
                image: require('../images/gris-microtexturado.webp'),

            },
            {
                id: 5,
                title: 'Negro Microtexturado',
                image: require('../images/negro-microtexturado.webp'),

            },
            {
                id: 6,
                title: 'Marron Claro',
                image: require('../images/marron-claro.webp'),

            },
            {
                id: 7,
                title: 'Marron Oscuro',
                image: require('../images/marron-oscuro.webp'),

            },
        ]
    },
}

export const pvcTexts: TextProductProps = {
    mainSection: {
        title: 'ABERTURAS DE PVC',
        image: require('../images/pvc.webp'),
        description: `Las aberturas de PVC REHAU son una solución innovadora y de alta calidad para ventanas y puertas.
                Fabricadas con PVC de última generación, estas aberturas ofrecen una combinación única de durabilidad,
                aislamiento térmico y acústico, así como resistencia a la intemperie y al impacto.
                Gracias a su diseño modular, las aberturas de PVC REHAU se adaptan fácilmente a una variedad de estilos
                arquitectónicos y necesidades de los clientes. Además, su mantenimiento es mínimo, ya que el PVC es
                un material resistente que no requiere pintura ni tratamiento especial.
                Con una amplia gama de colores y acabados disponibles, las aberturas de PVC REHAU pueden personalizarse
                para complementar cualquier diseño interior o exterior. Su excelente capacidad de aislamiento ayuda a
                mejorar la eficiencia energética de los edificios, lo que se traduce en ahorros significativos en
                costos de calefacción y refrigeración.`,
        systems: [{
            title: 'REHAU',
            types: ['EURO-DESIGN 60', 'HIGH DESIGN SLIDE', 'EURO DESIGN-SLIDE'],
            image: require('../images/rehau.png')
        }]
    },
    firstData: [
        {
            title: 'AISLAMIENTO SONORO',
            description: `El aislamiento sonoro en las aberturas de pvc proporciona una solución efectiva para 
                reducir la transmisión de ruido no deseado desde el exterior hacia el interior de una casa, así 
                como para minimizar la propagación del ruido generado dentro de la casa hacia el exterior.`,
            image: require('../images/aislamiento-sonoro.jpg')
        },
        {
            title: 'AISLAMIENTO TERMICO',
            description: `El aislamiento térmico en las aberturas de pvc no solo mejora la eficiencia energética 
                y el confort interior, sino que también ofrece durabilidad y bajo mantenimiento, convirtiéndolo en una 
                opción excelente para cualquier proyecto de construcción o renovación.`,
            image: require('../images/aislamiento-sonoro.jpg')
        },
    ],
    profiles: [
        {
            title: 'PERFILES DE PVC',
            profiles: [
                {
                    id: 0,
                    title: 'Euro-Design 60',
                    description: 'Opciones constructivas de aperturas ilimitadas. Practicables, Oscilo-Batientes, Abatibles, Puertas, Fijos y muchas más.',
                    image: require('../images/euro-design-60.png'),
                },
                {
                    id: 1,
                    title: 'Euro-Design Slide',
                    description: 'Posibilidad de realizar ventanas corredizas desde 1 hasta 6 hojas móviles.',
                    image: require('../images/euro-design-slide.png'),
                },
                {
                    id: 2,
                    title: 'High-Design Slide',
                    description: 'Posibilidad de realizar ventanas corredizas desde 1 hasta 6 hojas móviles. Gran robustez y funcionamiento simple para soportar hasta 180kg de vidrio por hoja.',
                    image: require('../images/high-design-slide.png'),
                },

            ]
        }
    ],
    lastData: [
        {
            title: 'MULTIPLES TIPOLOGIAS',
            description: `Nos adaptamos a las particularidades de cada proyecto, diseñando aberturas que se ajusten 
                perfectamente a sus requerimientos específicos. Brindamos orientación experta a nuestros clientes para 
                garantizar que alcancen los resultados más favorables.`,
            image: require('../images/dibujo-aberturas.png')
        },
    ],
    colors: {
        title: 'VARIEDAD DE COLORES',
        description: `Podés personalizar las ventanas REHAU con una gran variedad de colores foliados. Podés elegir entre 
                diversas variantes de folios de acabado liso, acabado madera o metalizado. Que, además, son resistentes 
                a la intemperie y a los agentes ambientales, presentan una elevada solidez frente a la luz y son 
                fáciles de cuidar.`,
        colors: [
            {
                id: 0,
                title: 'Turner Oak Malt',
                image: require('../images/turner-oak-malt.webp'),

            },
            {
                id: 1,
                title: 'Sheffield Oak Alpine',
                image: require('../images/sheffield-oak-alpine.webp'),

            },
            {
                id: 2,
                title: 'Antracita',
                image: require('../images/antracita.webp'),

            },
            {
                id: 3,
                title: 'Black Brown',
                image: require('../images/black-brown.webp'),

            },
            {
                id: 4,
                title: 'Winchester',
                image: require('../images/winchester.webp'),

            },
            {
                id: 5,
                title: 'Roble Dorado',
                image: require('../images/roble-dorado.webp'),

            },
            {
                id: 6,
                title: 'Titanium',
                image: require('../images/titanium.webp'),

            },
            {
                id: 7,
                title: 'Monument Oak',
                image: require('../images/monument-oak.webp'),

            },
            {
                id: 8,
                title: 'Negro Mate',
                image: require('../images/negro-mate.webp'),

            },
            {
                id: 9,
                title: 'Habano',
                image: require('../images/habano.webp'),

            },
            {
                id: 10,
                title: 'Nogal',
                image: require('../images/nogal.webp'),

            },
            {
                id: 11,
                title: 'Nutmeg',
                image: require('../images/nutmeg-oak.webp'),

            },
            {
                id: 12,
                title: 'Quartz Mate',
                image: require('../images/quartz-mate.webp'),

            },
            {
                id: 13,
                title: 'Black Smoke',
                image: require('../images/black-smoke.webp'),

            },
            {
                id: 14,
                title: 'Antracita Mate',
                image: require('../images/antracita-mate.webp'),

            },
            {
                id: 15,
                title: 'Aluminio',
                image: require('../images/aluminio.webp'),

            },
            {
                id: 16,
                title: 'Sapeli',
                image: require('../images/sapeli.webp'),

            },
            {
                id: 17,
                title: 'Silver',
                image: require('../images/silver.webp'),

            },
            {
                id: 18,
                title: 'Wengue',
                image: require('../images/wengue.webp'),
            },
        ]
    },
}